<template>
    <!-- 中心首页 侧边导航栏 -->
    <div class="sidebar">
        <div class="userinfo flex-align" v-show="isShowUserInfo">
            <img class="avatar csp" :src="downloadURL + userInfo.avatar" :onerror="defaultAvatar" alt
                @click="$router.push('/my')" />
            <div>
                <p class="userinfo-name">
                    {{userInfo.name || '默认用户'}}
                    <img src="@/assets/images/home/V@2x.png" alt />
                    <span class="vip">1</span>
                </p>
                <p class="userinfo-title">{{userInfo.titleName || ''}}</p>
                <div class="info_center">
                    <el-select @change="changeRole" v-model="activeRole" placeholder="切换角色" class="select-role">
                        <el-option v-for="item in roleList" :key="item.value" :label="item.role" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <el-menu :default-active="$route.path" router active-text-color="#508EF9" @select="selectedMenuItem"
            ref="sidebarMenu">
            <template v-for="(item) in getCurrentList">
                <el-submenu :index="item.path" v-if="item.children.length != 0" :key="item.id">
                    <template slot="title">
                        <i :class="item.icon"></i>
                        <span slot="title">{{item.title}}</span>
                    </template>
                    <el-menu-item :index="each.path" v-for="(each) in item.children" :key="each.id">
                        {{each.title}}
                        <strong class="el-icon-arrow-right" v-show="$route.path === each.path"></strong>
                    </el-menu-item>
                    <!-- <div class="mask csp" v-if="item.title === '网络课程'" @click.stop=""></div> -->
                </el-submenu>

                <el-menu-item :index="item.path" v-else :key="item.id">
                    <i :class="item.icon"></i>
                    <span slot="title">{{item.title}}</span>
                    <strong class="el-icon-arrow-right" v-show="$route.path === item.path"></strong>
                </el-menu-item>
            </template>
        </el-menu>
        <div class="dialog">
            <el-dialog title="提示" :visible.sync="dialogVisible" width="40%" :before-close="closeDialog" top="35vh">
                <span>该项目暂未开通此功能</span>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {
        stringify
    } from 'qs';
    import {
        mapState
    } from "vuex";
    export default {
        data() {
            return {
                activeRole: '',
                dialogVisible: false,
                // 学生、老师、家长导航
                navList: [{
                        id: 0,
                        title: '学习概况',
                        path: '/home/schedule',
                        icon: 'el-icon-tickets',
                        children: []
                    },
                    {
                        id: 1,
                        title: '我的课程',
                        path: '/home/course',
                        icon: 'el-icon-copy-document',
                        children: [{
                                id: 11,
                                title: '同步课程',
                                path: '/home/course',
                            },
                            {
                                id: 12,
                                title: '异步课程',
                                path: '/home/courseAsync',
                            },
                            {
                                id: 13,
                                title: '网络课程',
                                path: '/home/internetCourse',
                            },
                        ]
                    },
                    {
                        id: -1,
                        title: '研训空间',
                        path: '/home/space',
                        icon: 'el-icon-orange',
                        children: []
                    },
                    {
                        id: 2,
                        title: '云端答疑',
                        path: '/home/question',
                        icon: 'el-icon-finished',
                        children: []
                    },
                    {
                        id: 3,
                        title: '我的任务',
                        path: '/home/mytask',
                        icon: 'el-icon-edit',
                        children: [{
                                id: 31,
                                title: '我的作业',
                                path: '/home/mytask',
                            },
                            {
                                id: 32,
                                title: '我的活动',
                                path: '/home/myActivity',
                            },
                            {
                                id: 167,
                                title: '调研问卷',
                                path: '/home/WjxTeaList',
                            },
                        ]
                    },
                    {
                        id: 4,
                        title: '能力报告',
                        path: '/home/report',
                        icon: 'el-icon-data-analysis',
                        children: []
                    },
                    {
                        id: 5,
                        title: '我的学分',
                        path: '/home/credit',
                        icon: 'el-icon-medal-1',
                        children: []
                    },
                ],
                // 专家、助教导航
                expertList: [{
                        id: 17,
                        title: '项目概况',
                        path: '/home/schedule',
                        icon: 'el-icon-tickets',
                        children: [],

                    },
                    {
                        id: 6,
                        title: '我的团队',
                        path: '/home/team',
                        icon: 'el-icon-user',
                        children: [],
                    },
                    {
                        id: 7,
                        title: '我的课程',
                        path: '/home/course',
                        icon: 'el-icon-copy-document',
                        children: [{
                                id: 71,
                                title: '同步课程',
                                path: '/home/course',
                            },
                            {
                                id: 72,
                                title: '异步课程',
                                path: '/home/courseAsync',
                            },
                            {
                                id: 73,
                                title: '网络课程',
                                path: '/home/internetCourse',
                            },
                        ],
                    },
                    {
                        id: 8,
                        title: '教学管理',
                        path: '/home/manage',
                        icon: 'el-icon-folder-opened',
                        children: [{
                                id: 81,
                                title: '项目课管理',
                                path: '/home/manage/project',
                            },
                            {
                                id: 82,
                                title: '公开课管理',
                                path: '/home/manage/public',
                            },
                            {
                                id: 83,
                                title: '活动管理',
                                path: '/home/manage/activity',
                            },
                            {
                                id: 84,
                                title: '作业管理',
                                path: '/home/manage/job',
                            },
                            {
                                id: 85,
                                title: '签到管理',
                                path: '/home/manage/signIn',
                            },
                            // {
                            //     id: 86,
                            //     title: '研讨沙龙',
                            //     path: '/home/manage/salon',
                            // },
                            {
                                id: 87,
                                title: '调研问卷',
                                path: '/home/manage/wjx',
                            },
                            // {
                            //     id: 88,
                            //     title: '研训空间',
                            //     path: '/home/manage/space',
                            // },
                        ],
                    },
                    {
                        id: 9,
                        title: '项目动态',
                        path: '/home/news',
                        icon: 'el-icon-share',
                        children: [],
                    },
                    {
                        id: 10,
                        title: '云端答疑',
                        path: '/home/question',
                        icon: 'el-icon-finished',
                        children: [],
                    },
                    {
                        id: 101,
                        title: '答疑解惑',
                        path: '/home/explain',
                        icon: 'el-icon-chat-line-square',
                        children: [],
                    },
                    {
                        id: 18,
                        title: '项目报告',
                        path: '/home/project_data',
                        icon: 'el-icon-data-analysis',
                        children: []
                    }
                ],
                // 领导、学校导航
                leaderList: [{
                        id: 11,
                        title: '项目概况',
                        path: '/home/leader_home',
                        icon: 'el-icon-tickets',
                        children: []
                    },
                    {
                        id: 12,
                        title: '我的团队',
                        path: '/home/team',
                        icon: 'el-icon-user',
                        children: []
                    },
                    {
                        id: 13,
                        title: '项目动态',
                        path: '/home/news',
                        icon: 'el-icon-share',
                        children: []
                    },
                    {
                        id: 14,
                        title: '活动报告',
                        path: '/home/leader_report',
                        icon: 'el-icon-document-copy',
                        children: []
                    },
                    {
                        id: 19,
                        title: '项目报告',
                        path: '/home/project_data',
                        icon: 'el-icon-data-analysis',
                        children: []
                    }
                ],
                managerList: [{
                        id: 15,
                        title: '项目管理',
                        path: '/home/weekly',
                        icon: 'el-icon-document-copy',
                        children: [{
                                id: 151,
                                title: '周报管理',
                                path: '/home/weekly',
                            }, {
                                id: 152,
                                title: '回顾管理',
                                path: '/home/reivew',
                            },
                            {
                                id: 153,
                                title: '习题管理',
                                path: '/home/examQuest',
                            },
                            {
                                id: 154,
                                title: '活动管理',
                                path: '/home/totalActivity',
                            },
                            {
                                id: 155,
                                title: '项目课管理',
                                path: '/home/totalCourse',
                            },
                            {
                                id: 156,
                                title: '作业管理',
                                path: '/home/totalWork',
                            },
                            {
                                id: 157,
                                title: '学分管理',
                                path: '/home/score',
                            },
                            // {
                            //     id: 158,
                            //     title: '沙龙管理',
                            //     path: '/home/totalSalon',
                            // },
                            {
                                id: 87,
                                title: '调研问卷',
                                path: '/home/manage/wjx',
                            },
                            {
                                id: 88,
                                title: '研训空间',
                                path: '/home/manage/space',
                            },
                            {
                                id: 89,
                                title: '团队管理',
                                path: '/home/manage/team_manage',
                            },
                            {
                                id: 90,
                                title: '报告管理',
                                path: '/home/totalReport',
                            },
                             {
                                id: 91,
                                title: '观思问管理',
                                path: '/home/observe',
                            },
                            
                        ]
                    },
                    {
                        id: 16,
                        title: '数据统计',
                        path: '/home/courseStatistics',
                        icon: 'el-icon-document-copy',
                        children: [{
                                id: 161,
                                title: '课程统计',
                                path: '/home/courseStatistics',
                            },
                            {
                                id: 162,
                                title: '人员统计',
                                path: '/home/personnel',
                            },
                            {
                                id: 163,
                                title: '作业统计',
                                path: '/home/workStatistics',
                            },
                            {
                                id: 164,
                                title: '考勤统计',
                                path: '/home/attendanceStatistics',
                            },
                            {
                                id: 165,
                                title: '优秀作业统计',
                                path: '/home/excellentworkStat',
                            },
                            {
                                id: 166,
                                title: '学分统计',
                                path: '/home/scoreStatistics',
                            }
                        ]
                    }
                ]
            };
        },

        computed: {
            ...mapState({
                role: state => state.user.role,
                roleList: state => state.user.userRoleList,
                userInfo: state => state.user.userInfo,
                defaultAvatar: state => state.user.defaultAvatar,
            }),
            isShowUserInfo() {
                if (this.role == 'manager' || this.role == 'leader' || this.role == 'school') {
                    return true;
                }
                return false;
            },
            getCurrentList() {
                let map = {
                    'parent': 'navList', //家长
                    'normal': 'navList', //师范生
                    'teacher': 'navList', //老师
                    'student': 'navList', //学生
                    'parent': 'navList',
                    'expert': 'expertList', //专家
                    'assistant': 'expertList', //助教
                    'school': 'leaderList', //学校
                    'leader': 'leaderList', //领导（管理者）
                    'manager': 'managerList', //管理员
                }
                if (this.$store.state.user.userInfo.id == 8563 && this.$store.state.user.role == 'expert') {
                    for (let i = 0; i < this.expertList.length; i++) {
                        if (this.expertList[i].id == 8) {
                            this.expertList.splice(i, 1)
                            break;
                        }
                    }
                    // console.log(this.expertList);
                    return this.expertList
                }
                return this[`${map[this.$store.state.user.role]}`]
            },
        },
        watch: {
            'role': {
                handler(newVal, oldVal) {
                    console.log('监听角色发生改变--', newVal)
                    if (!newVal) return;
                    this.activeRole = newVal;
                }
            },
            activeRole: {
                handler(newVal, oldVal) {
                    if (!newVal) {
                        this.activeRole = this.role
                    }
                },
                immediate: true
            }
        },
        methods: {
            // 关闭提示弹窗
            closeDialog() {
                this.dialogVisible = false;
            },
            // 选中Menu时的操作
            selectedMenuItem(activePath) {
                console.log(activePath);
                if (activePath == '/home/project_data') {
                    window.open(
                        `https://dctest.lezhireading.com/#/leader_data?projectId=${this.$store.state.user.currentProject.id}&projectName=${this.$store.state.user.currentProject.name}&token=${this.$store.state.user.token}&roleId=${this.$store.state.user.roleId}`
                        )
                    // window.open(`http://localhost:8081/#/leader_data?projectId=${this.$store.state.user.currentProject.id}&projectName=${this.$store.state.user.currentProject.name}&token=${this.$store.state.user.token}&roleId=${this.$store.state.user.roleId}`)
                }
                // 网络课程跳转 ================= Start
                if (!activePath) {
                    this.$Api.Home.checkHasThirdUser().then(res => {
                            console.log(res);
                            if (res.data.flag) {
                                this.$Api.Home.getAuthKey({
                                    id: res.data.authId
                                }).then(resData => {
                                    // console.log(resData);
                                    window.open(
                                        `${resData.data.url}&ticket=${resData.data.ticket}&platform=pc`);
                                }).catch(err => {
                                    console.log(err);
                                })
                            }
                            if (!res.data.flag) {
                                this.dialogVisible = true;
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        });
                    return;
                }
                // 网络课程跳转 ================= End
                this.closeSubmenu(activePath);
            },
            // 关闭打开的子菜单
            closeSubmenu(activePath) {
                let colsedSubmenuPath = [];
                let pathArray = [];
                // 循环当前菜单 存储带有子菜单的地址
                this.getCurrentList.map(item => {
                    if (JSON.stringify(item.children) !== '[]') {
                        colsedSubmenuPath.push(item.path);
                        let childrenPath = [];
                        item.children.map(each => {
                            childrenPath.push(each.path);
                        })
                        pathArray.push(childrenPath);
                    }
                })
                // 点击的地址与带有子菜单的地址不相同 则关闭子菜单
                colsedSubmenuPath.map((item, index) => {
                    if (pathArray[index].indexOf(activePath) == -1) {
                        this.$refs['sidebarMenu'].close(item);
                    }
                })
            },
            changeRole() {
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(255, 255, 255, 0.7)'
                });
                // sessionStorage.setItem('role', this.activeRole);
                this.$store.commit('getRole', this.activeRole);
                this.roleList.map(item => {
                    if (this.activeRole == item.value) {
                        // sessionStorage.setItem('roleId', item.id);
                        this.$store.commit('getRoleId', item.id);
                    }
                })
                this.$router.push(this.getCurrentList[0].path);
                // this.$refs['sidebarMenu'].close('/home/course');
                loading.close();
            },
            async getTopLevel() {
                let resData = await this.$Api.Home.getTopLevel();
                // console.log(resData);
                if (!resData.data || JSON.stringify(resData.data) == '[]') return;
                if (resData.data[0].id === 124) {
                    this.navList.push({
                        title: '综合评价',
                        path: '/home/evaluate',
                        icon: 'el-icon-document-copy',
                        children: []
                    })
                }
            },
            init() {
                this.activeRole = this.role;
                this.getTopLevel();
            }

        },
        activated() {
            // console.log('sidebar');
            this.init();
        },
    };
</script>

<style lang="less" scoped>
    .sidebar {
        flex-shrink: 0;
        width: 177px;
        margin-right: 21px;
        padding: 0 6px 10px 6px;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 150px;
        box-shadow: 0px 2px 10px 0px #F5F8FA;
        // box-sizing: border-box;
        // transition: 1s all;

        .userinfo {
            padding: 20px 0 0px 21px;

            .avatar {
                width: 45px;
                height: 45px;
                margin-right: 12px;
                border-radius: 50%;
            }

            .userinfo-name {
                font-size: 14px;
                font-weight: 600;

                .vip {
                    color: #FCD012;
                }
            }

            .userinfo-title {
                color: #999999;
                font-size: 12px;
                margin-top: 4px;
            }

            /deep/ .select-role {
                .el-input__inner {
                    width: 80px;
                    border: 0px;
                    padding-left: 0;
                    font-size: 12px;
                    color: #999;
                    line-height: 26px;
                    height: 26px;
                }

                .el-input__icon {
                    line-height: 24px;
                    font-size: 12px;
                }
            }
        }

        .radio-group {
            display: none;
        }

        .el-menu {
            width: 100%;
            border-right: none;
            margin-top: 24px;
        }

        .el-menu-item {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            margin-bottom: 5px;
            padding-left: 33px !important;
            padding-right: 12px;
            border-radius: 5px;
            line-height: 55px;
            height: 55px;
            font-size: 14px;
            user-select: none;


            &:focus,
            &:hover {
                outline: 0;
                background-color: rgba(35, 115, 236, .05);
            }

            i {
                margin-right: 12px;
                line-height: inherit;
                font-size: 16px;
            }

            // span {
            //     transition: 1s all;
            // }

            strong {
                order: 1;
                margin-left: 12px;
                font-size: 12px;
            }

            &.is-active {
                background-color: rgba(35, 115, 236, .05);

                strong {
                    display: block;
                    font-weight: bold;
                }
            }


        }

        /deep/.el-submenu {
            border-radius: 5px;

            .el-submenu__title {
                margin-bottom: 5px;
                padding-left: 33px !important;
                line-height: 55px;
                height: 55px;
                font-size: 14px;
                // color: #595959;

                i {
                    margin-right: 12px;
                    font-size: 16px;
                }

                i.el-submenu__icon-arrow {
                    right: 22px;
                    margin-top: -3px;
                    margin-right: 0;
                    font-size: 12px;
                    font-weight: bold;
                }

                &:focus,
                &:hover {
                    border-radius: 5px;
                    outline: 0;
                    background-color: rgba(35, 115, 236, .05);
                }
            }

            .el-menu-item {
                padding-left: 75px !important;
                line-height: 53px;
                height: 53px;
                min-width: auto;
                user-select: none;
                color: #888;
                font-size: 13px;

                &:focus,
                &:hover {
                    outline: 0;
                    background-color: rgba(35, 115, 236, .05);
                }
            }
        }

        .dialog {

            /deep/.el-dialog {
                border-radius: 4px;
            }

            /deep/.el-dialog__header {
                background-image: url("../../assets/images/teach/create-title.png");
                background-size: cover;
                padding: 0px;
                line-height: 40px;
                text-align: center;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;


                .el-dialog__title {
                    color: #fff;
                    font-size: 16px;
                }

                .el-dialog__headerbtn {
                    top: 10px;
                }

                .el-icon-close:before {
                    color: #fff;
                }
            }

            /deep/.el-dialog__footer {
                .el-button--primary {
                    background-color: #508EF9;
                    border-color: #508EF9;
                    padding: 0 25px;
                    line-height: 30px;
                }
            }

            /deep/ .el-dialog__body {
                color: #666;
                padding: 30px 30px 30px;
                text-align: center;
            }
        }

        @media screen and (min-width : 1250px) {
            margin-right: 26px;
            width: 214px;

            .userinfo {
                .avatar {
                    width: 51px;
                    height: 51px;
                    margin-right: 18px;
                }

                .userinfo-name {
                    font-size: 15px;
                }
            }

            .el-menu-item {
                padding-left: 51px !important;
                padding-right: 20px;

                i {
                    margin-right: 17px;
                    font-size: 18px;
                }

                strong {
                    margin-left: 17px;
                    font-size: 14px;
                }
            }


            /deep/.el-submenu {

                .el-submenu__title {
                    padding-left: 51px !important;
                    line-height: 55px;
                    height: 55px;


                    i {
                        margin-right: 17px;
                        font-size: 18px;
                    }

                    i.el-submenu__icon-arrow {
                        right: 29px;
                        margin-top: -4px;
                        font-size: 14px;
                    }
                }

                .el-menu-item {
                    padding-left: 95px !important;
                }

            }

            .dialog {
                /deep/.el-dialog__header {
                    line-height: 50px;

                    .el-dialog__title {
                        font-size: 18px;
                    }
                }

                /deep/.el-dialog__footer {
                    .el-button--primary {
                        padding: 0 30px;
                        line-height: 35px;
                    }
                }

                /deep/ .el-dialog__body {
                    font-size: 16px;
                }
            }
        }
    }
</style>